import React, { Component } from "react";
import Addvolunteer from "./Addvolunteer";
import UpdateSurgery from "./UpdateSurgery";
import './Volunteertab.scss';
import { Tabs, Tab } from "react-bootstrap";

class Volunteertab extends Component {
  render() {
    return (
      <div>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
          <Tab eventKey="home" title="Add new volunteer">
            <Addvolunteer />
          </Tab>
          <Tab eventKey="updatedischarge" title="Update surgery date">
            <UpdateSurgery />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default Volunteertab;
