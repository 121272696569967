import React, { Component } from 'react';
import './Addvolunteer.scss';
import { BASE_URL, get,getDashboardDetails } from "../config/Api";
import Moment from 'moment';


class Downloadreport extends Component {
  constructor() {
    super();
    this.state = {
      loginRes: undefined,
    };
  }

  getDashboardData = () => {
    const userDetails = JSON.parse(localStorage.getItem( "users" ) );
    const getToken = userDetails.token;
    // getting dashboard details
    get(getDashboardDetails, getToken, (response,error) => {
        if(error || response.status_code !== 200){
            console.log("Something went wrong");
        }
        else {
         this.setState({
          lastDownloadResponse: response.data.last_download,
            });
        }
    })
 }

  componentDidMount = () => {
    this.getDashboardData();
    const userDetails = JSON.parse(localStorage.getItem( "users" ) );
    const lastDownload = userDetails.last_download;
    this.setState({
      loginRes: lastDownload,
    });
  }

  onDownloadReportClick = () => {
    const userDetails = JSON.parse(localStorage.getItem( "users" ) );
    const getToken = userDetails.token;
    window.open(`${BASE_URL}/admin/export/?token=${getToken}`, '_blank');
  }
    render() {
        return (
            <div>
            <section className="add-volunteer">
            <h3>Download survey report<span className="ml-1">last dowloaded {Moment(this.state.lastDownloadResponse).fromNow()}</span></h3>
            
            <div className="volunteer-form">
              <form>
                  <div class="download--report">
                    <div className="form-group mb-0">
                      <label>Language of report</label>
                  
                    <div className="input-group mb-3">
                    <select className="form-control">
                        <option>English</option>
                      </select>
                    <div className="input-group-append">
                      <button className="btn btn-login btn--download" type="button" onClick={() => this.onDownloadReportClick()}>Download  here </button>
                    </div>
                    </div>
                    </div>
                  </div>
 
                  
              </form>
            </div>
          </section>
            </div>
        );
    }
}

export default Downloadreport;