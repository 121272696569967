import React, { Component } from 'react';
import "./Addedpopup.scss";
import { Modal, Button } from "react-bootstrap";
import Success from '../assets/done.svg';
class Addedpopup extends Component {
    render() {
        return (
            <div className="modal--holder" >
            <Modal show={this.props.show}>
            <Modal.Body>
                <div className="text-center mb-4">
                 <img src={Success} alt="success" />
                </div>
                <div className="success-details text-center">
                    <p>Volunteer added successfully!</p>
                    <p>ID : {this.props.newVolunteerData}</p>
                    <p>login details have been shared on registered Email ID.</p>
                </div>
                <div className="text-center">
                        <Button className="btn btn-login" onClick={this.props.onClose}>
                        Done
                    </Button>
                </div>

            </Modal.Body>
          </Modal>
    
            </div>
        );
    }
}

export default Addedpopup;