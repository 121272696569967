import React, { Component } from 'react';
import './Header.scss';
import Logo from '../assets/Logo.svg';
class Header extends Component {
   
    onLogout(){
        localStorage.removeItem("users")
        this.props.isLogedin(true);
    }

    render() {
        return (
            <div className="header-holder">
            <div className="container">
               <div className="d-flex justify-content-between align-items-center">
                 <div className="d-flex align-items-center">
                     <div>
                      <img src={Logo} alt="logo" />
                     </div>
                     <div className="selected-list d-flex align-items-baseline ml-2">
                        <p>Preferences Patient Perceptions</p>
                        <p> Survey Dashboard</p>
                     </div>
                 </div>
                 <div className="">
                  <button className="btn btn-logout" onClick={() => this.onLogout()}>Logout</button>
                 </div>
               </div>
            </div>
            </div>
        );
    }
}


export default Header;