import React, { Component } from "react";
import {
  put,
  get,
  editUpdateSurgery,
  getHospitalslist,
  getUsersearch,
} from "../config/Api";
import $ from "jquery";
import DischargeUpdatedpopup from "./DischargeUpdatedpopup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class UpdateSurgery extends Component {
  constructor() {
    super();
    this.state = {
      hospitalsList: [],
      selectedhospitalsList: [],
      selectedUserId: [],
      dischargeHospitalId: undefined,
      //   selectedUserId: undefined,
      dischargeDate: undefined,
    };
    this.dateChanged = this.dateChanged.bind(this);
  }

  dateChanged(dateSelected) {
    this.setState({ dischargeDate: dateSelected });
  }

  componentDidMount = () => {
    $(".css-1wa3eu0-placeholder").text("Select Volunteer ID");
    this.getHospitalsData();
  };

  // GET API for hospitals list
  getHospitalsData = () => {
    const userDetails = JSON.parse(localStorage.getItem("users"));
    const getToken = userDetails.token;
    get(getHospitalslist, getToken, (response, error) => {
      if (error || response.status_code !== 200) {
        console.log("Something went wrong");
      } else {
        this.setState({
          hospitalsList: response.data,
        });
      }
    });
  };

  onHospitalSelect = (dischargeHospitalId) => {
    const userDetails = JSON.parse(localStorage.getItem("users"));
    const getToken = userDetails.token;
    this.setState({ dischargeHospitalId });
    const selectedHospitalId = dischargeHospitalId;
    // getting hospitals list
    get(
      getUsersearch + selectedHospitalId + "/",
      getToken,
      (response, error) => {
        if (error || response.status_code !== 200) {
          console.log("Something went wrong", error);
        } else {
          this.setState({
            selectedhospitalsList: response.data,
          });
        }
      }
    );
  };


  onUseridSelect = (selectedUserId) => {
    this.setState({ selectedUserId });
  };

  // page refresh
  refreshPage() {
    window.location.reload();
  }
  // validating form
  validateDischargeObject = () => {
    const { dischargeHospitalId, selectedUserId, dischargeDate } = this.state;
    $(".error-msg").remove();
    if (dischargeHospitalId === "" || dischargeHospitalId === undefined) {
      $("#dischargehospitalError").append(
        '<p class="error-msg">Please select hospital</p>'
      );
      return false;
    }
    if (selectedUserId.value === "" || selectedUserId.value === undefined) {
      $("#userIdError").append(
        `<p class="error-msg">Please select volunteer ID</p>`
      );
      return false;
    }
    if (dischargeDate === "" || dischargeDate === undefined) {
      $("#dischargeDateError").append(
        `<p class="error-msg">Please select discharge date</p>`
      );
      return false;
    }
    return true;
  };

  // update Discharge and calling PUT API
  onUpdateDischarge = () => {
    const userDetails = JSON.parse(localStorage.getItem("users"));
    const getToken = userDetails.token;
    if (this.validateDischargeObject() === true) {
      const bodyRequest = {
        discharge_date: Moment(this.state.dischargeDate).format("YYYY-MM-DD"),
      };
      put(
        editUpdateSurgery + this.state.selectedUserId.value + "/",
        bodyRequest,
        getToken,
        (response, error) => {
          if (error || response.status_code !== 200) {
            console.log("Sorry something went wrong...", error);
            $("#invalidError").append(
              `<p class="error-msg">${response.data.message}</p>`
            );
          } else {
            this.setState({
              openDischargeModal: true,
              dischargeResponse: response.data.username,
              surveyDates: response.data.dates
            });
          }
        }
      );
    }
  };

  render() {
    const hospitals = this.state.hospitalsList;
    let options = this.state.selectedhospitalsList.map(function (item) {
      return { value: item.id, label: item.username };
    });
    const { selectedUserId } = this.state;
    return (
      <div>
        <DischargeUpdatedpopup
          show={this.state.openDischargeModal}
          dischargedUserID={this.state.dischargeResponse}
          dischargeData={this.state.dischargeDate}
          resSurveyDates={this.state.surveyDates}
          onClose={() =>
            this.setState(
              {
                openDischargeModal: false,
                dischargeHospitalId: "",
                selectedUserId: "",
                dischargeDate: "",
              },
              () => {
                this.refreshPage();
              }
            )
          }
        />
        <section className="add-volunteer">
          <div className="volunteer-form">
            <form>
              <div className="form-group">
                <label>Hospital</label>
                <select
                  className="form-control selectpicker"
                  data-live-search="true"
                  onChange={(event) => {
                    this.onHospitalSelect(event.target.value);
                  }}
                >
                  <option disabled selected>
                    Select hospital
                  </option>
                  {hospitals.length > 0 &&
                    hospitals.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>

                <span id="dischargehospitalError"></span>
              </div>
              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label>Volunteer ID</label>
                    <React.Fragment>
                      <Select
                        className="custom--search"
                        value={selectedUserId}
                        onChange={this.onUseridSelect}
                        options={options}
                      />
                    </React.Fragment>
                    <span id="userIdError"></span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Surgery date</label>
                 <div>
                      <DatePicker
                        placeholderText="Select Date" readOnly
                        mode="date"
                        dateFormat="Do MMM YYYY"
                        date={this.state.dischargeDate}
                        selected={this.state.dischargeDate}
                        onChange={this.dateChanged}
                      />
                 </div>
                    <span id="dischargeDateError"></span>
                    <span id="invalidError"></span>
                  </div>
                </div>
              </div>

              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-login mt-2"
                  onClick={() => this.onUpdateDischarge()}
                >
                  Update info
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default UpdateSurgery;
