import React, { Component } from "react";
import Header from "./Header";
import Volunteersregistered from "./Volunteersregistered";
import Downloadreport from './Downloadreport';
import Login from './Login';
import Volunteertab from "./Volunteertab";

import "./Dashboard.scss";

class Dashboard extends Component {
 
    constructor(props){
        super(props);
       this.state = {
        isLogedin: true,
        userInfo: ''
       }
    }
    componentDidMount(){
        const user = localStorage.getItem("users");
        this.setState({isLogedin:user === null || user === undefined ? true : false, userInfo:JSON.parse(user)}) 
    }

  

  render() {
    return (
      <div>
        <Header isLogedin={(login)=>{
            this.setState({isLogedin:login})
        }
        }/>
        <div className="container">
        <Login isLogedin={(login,userInfo)=>{
            this.setState({isLogedin:login,userInfo})
        }
        } show = {
            this.state.isLogedin
        } />

          {
              this.state.isLogedin ? null :    <div className="dashboard-layout">
              <Volunteersregistered volunteers = {
                  this.state.userInfo
              } surveydata = {
                  this.state.userInfo && this.state.userInfo.survey_data
              }  />
              <div className="row mb-4">
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                <Volunteertab />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <Downloadreport />
                </div>
              </div>
            </div> 
          }
        
        </div>
      </div>
    );
  }
}

export default Dashboard;
