import React from "react";
import "./Login.scss";
import { Modal, button } from "react-bootstrap";
import { login, post_login } from "../config/Api";
import Logo from '../assets/Logo.svg';

import $ from 'jquery';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: undefined,
      password: undefined,
    };
  }

  onLoginClick = (loginId) => {
    this.setState({ loginId });
  };

  onpasswordClick = (password) => {
    this.setState({ password });
  };

  validateObject = () => {
    const { loginId, password } = this.state;
    $(".error-msg").remove();
    if (loginId === "" || loginId === undefined) {
        $("#loginError").append('<p class="error-msg">Please enter the Login ID</p>')
      return false;
    }
    if (password === "" || password === undefined) {
      $("#passwordError").append('<p class="error-msg">Please enter the password</p>')
      return false;
    }
    return true;
  };

  // onclick 
  onLogin = () => {
    if (this.validateObject() === true) {
      const bodyRequest = {
        email: this.state.loginId,
        password: this.state.password,
      };
      post_login(login, bodyRequest,  (response, error) => {
          if( error || response.status_code !== 200){
              console.log("Sorry something went wrong...", error);
              $("#invalidError").append(`<p class="error-msg">Entered invalid credentials, Try again.</p>`)
          }
          else {
              this.setState({
                loginId : '',
                password: ''
              })
              localStorage.setItem("users",JSON.stringify(response.data));
              this.props.isLogedin(false,response.data);
          }
      })
    }
  };

  


  render() {
    return (
      <div className="login-modal-holder">
      <Modal show={this.props.show} className="custom-modal" animation={false}>
        <Modal.Body>
       <div className="d-flex align-items-center justify-content-center mb-5">
          <div className="text-center ">
          <img className="custom-logo-width" src={Logo} alt="logo" />
         </div>
         <div>
          <p className="admin-title mb-0">PPP admin dashboard</p>
         </div>
       </div>
       
        <div>
          <div className="login-holder">
            <form>
              <div className="form-group">
                <label>Login ID</label>
                <input
                  type="text"
                  name="loginId"
                  className="form-control"
                  value={this.loginId}
                  onChange={event => {
                    this.onLoginClick(event.target.value)
                }}
                  placeholder="ID"
                />
                <span id="loginError"></span>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={this.password}
                  onChange={event => {
                    this.onpasswordClick(event.target.value)
                }}
                  placeholder="Password"
                />
                <span id="passwordError"></span>
                <span id="invalidError"></span>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-login mt-3"
                  onClick={() => this.onLogin()}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    );
  }
}

export default Login;
