import React, { Component } from "react";
import "./Addvolunteer.scss";
import Addedpopup from "./Addedpopup";
import { post, get, getHospitalslist, addVolunteer } from "../config/Api";
import $ from "jquery";
class Addvolunteer extends Component {
  constructor() {
    super();
    this.state = {
      hospitalsList: [],
      hospitalId: undefined,
      patientEmail: undefined,
      typeCohort: "",
      volunteerResponse: "",
    };
  }

  componentDidMount = () => {
    const userDetails = JSON.parse(localStorage.getItem("users"));
    const getToken = userDetails.token;
    // getting hospitals list
    get(getHospitalslist, getToken, (response, error) => {
      if (error || response.status_code !== 200) {
        console.log("Something went wrong");
      } else {
        this.setState({
          hospitalsList: response.data,
        });
      }
    });
  };

  // onChange functions
  onHospitalSelect = (hospitalId) => {
    this.setState({ hospitalId });
  };

  onCohortSelect = (typeCohort) => {
    this.setState({ typeCohort });
  };

  onPatientsEmailClick = (patientEmail) => {
    this.setState({ patientEmail });
  };

  // page refresh
  refreshPage() {
    window.location.reload();
  }

  // validating form
  validateVolunteersObject = () => {
    const email_pattern = /^.+@.+[.].{2,}$/i;
    const { hospitalId, patientEmail } = this.state;
    $(".error-msg").remove();
    if (hospitalId === "" || hospitalId === undefined) {
      $("#hospitalError").append(
        '<p class="error-msg">Please select hospital</p>'
      );
      return false;
    }
    if (patientEmail === "" || patientEmail === undefined) {
      $("#patientError").append(
        `<p class="error-msg">Please enter patient's email id</p>`
      );
      return false;
    } else if (!email_pattern.test(patientEmail)) {
      $("#patientError").append(
        `<p class="error-msg">Please enter valid email id</p>`
      );
      return false;
    }
    return true;
  };

  // submitting form and calling POST API
  onAddvolunteer = () => {
    const userDetails = JSON.parse(localStorage.getItem("users"));
    const getToken = userDetails.token;
    if (this.validateVolunteersObject() === true) {
      const bodyRequest = {
        email: this.state.patientEmail,
        hospital: this.state.hospitalId,
        type_of_cohort: this.state.typeCohort,
      };

      post(addVolunteer, bodyRequest, getToken, (response, error) => {
        if (error || response.status_code !== 201) {
          console.log("Sorry something went wrong...", error);
          $("#invalidError").append(
            `<p class="error-msg">${response.data.message}</p>`
          );
        } else {
          this.setState({
            openModal: true,
            volunteerResponse: response.data.username,
          });
        }
      });
    }
  };

  render() {
    const hospitals = this.state.hospitalsList;
    return (
      <div>
        <Addedpopup
          show={this.state.openModal}
          newVolunteerData={this.state.volunteerResponse}
          onClose={() =>
            this.setState({
              openModal: false,
              hospitalId: "",
              patientEmail: "",
              typeCohort: "",
            }, () => { this.refreshPage() })
          }
        />
        <section className="add-volunteer">
          <div className="volunteer-form">
            <form>
              <div className="form-group">
                <label>Hospital</label>
                <select id="selectHospital"
                  className="form-control"
                  onChange={(event) => {
                    this.onHospitalSelect(event.target.value);
                  }}
                >
                  <option disabled selected>
                    Select hospital
                  </option>
                  {hospitals.length > 0 &&
                    hospitals.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                <span id="hospitalError"></span>
              </div>
              <div className="form-group">
                <label>Patient’s email id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Patient's email"
                  value={this.state.patientEmail}
                  onChange={(event) => {
                    this.onPatientsEmailClick(event.target.value);
                  }}
                />
                <span id="patientError"></span>
              </div>
              <div className="form-group">
                <label>Type of Cohort</label>
                <select className="form-control" onChange={(event) => {
                  this.onCohortSelect(event.target.value);
                }}>
                  <option value="" selected disabled>Select type of cohort</option>
                  <option value="Peritoneal metastases GI">Peritoneal metastases GI </option>
                  <option value="Colorectal metastases">Colorectal metastases
                </option>
                  <option value="Peritoneal metastases GYN">Peritoneal metastases GYN
                </option>
                  <option value="Non-metastatic colorectal cancer">Non-metastatic colorectal cancer
                </option>
                </select>
                <span id="invalidError"></span>
              </div>
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-login mt-1"
                  onClick={() => this.onAddvolunteer()}
                >
                  Add Volunteer
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default Addvolunteer;
