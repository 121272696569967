import React, { Component } from "react";
import "./VolunteersRegistered.scss";
import { get,getDashboardDetails } from "../config/Api";


class Volunteersregistered extends Component {
  constructor(){
    super()
    this.state = {
      dashboardData: [],
      surveyData: [] 
    }
  }
  getDashboardData = () => {
    const userDetails = JSON.parse(localStorage.getItem( "users" ) );
    const getToken = userDetails.token;
    // getting dashboard details
    get(getDashboardDetails, getToken, (response,error) => {
        if(error || response.status_code !== 200){
            console.log("Something went wrong");
        }
        else {
         this.setState({
          dashboardData: response.data,
          surveyData: response.data.survey_data
            });
        }
    })
 }
 
  componentDidMount = () => {
     this.getDashboardData();
  }
    
  render() {
    const survey = this.state.dashboardData;
    const surveyDetails = this.state.surveyData;

    return (
      <div>
      <div className="registered-holder">
      <div className="register-details d-flex justify-content-between">
        <p>Volunteers registered</p>
        <p>{survey.volunteers_registered}</p>
      </div>
      <p className="response-summary">Responses summary</p>
      {
        surveyDetails.length > 0 && surveyDetails.map((item) => (
         <div className="survey-details d-flex justify-content-between">
         <p>{item.survey_name} survey</p>
         <p>{item.response} responded</p>
       </div>
        ))
      }
   
    </div>
      </div>
    );
  }
}

export default Volunteersregistered;
