import React, { Component } from "react";
import "./Addedpopup.scss";
import { Modal, Button } from "react-bootstrap";
import Success from "../assets/done.svg";
import Moment from "moment";

class DischargeUpdatedpopup extends Component {
 
  render() {
    const responseDates = this.props.resSurveyDates;
    return (
      <div>
        <Modal show={this.props.show}>
          <Modal.Body>
            <div className="text-center mb-4">
              <img src={Success} alt="success" />
            </div>
            <div className="success-details text-center">
              <p>
                Surgery set to {Moment(this.props.dischargeData).format("Do MMM YYYY")}
              </p>
              <p>For ID : {this.props.dischargedUserID}</p>
              <p className="mb-0">Volunteer will recieve next surveys on </p>
              <div className="d-flex justify-content-center">
                {responseDates && responseDates.length > 0 &&
                  responseDates.map((individualDate) => (
                    <p className="discharge--dates">
                      {Moment(individualDate.date).format("Do MMM YYYY")}
                      <span className="mx-1">.</span>
                    </p>
                  ))}
              </div>
            </div>
            <div className="text-center">
              <Button className="btn btn-login" onClick={this.props.onClose}>
                Done
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DischargeUpdatedpopup;
